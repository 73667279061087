@import "../styles/colors";

// ---------- Drawer
.ant-drawer {
  &-content {
    background-color: #fff;
  }

  &-close {
    position: absolute;
    right: 0;
  }

  &-title > div {
    font-weight: bold;
  }

  &-header .ant-drawer-header-title .ant-drawer-title .ng-star-inserted {
    font-size: 1.5rem;
    color: #282c34;
    font-weight: 600;
  }

  &-content-wrapper.pinkyBackground {
    .drawer-upper-container {
      width: 90%;
    }

    .ant-input[disabled],
    .ant-picker.ant-picker-disabled,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: unset;
      border: 1px solid #D5D9DC;
      border-radius: 20px;
    }

    .ant-drawer-header {
      background-color: #F7E4EC;
    }
  }

  &-content-wrapper.publicGroupBackground {
    .drawer-upper-container {
      width: 90%;
    }

    .ant-drawer-header, .ant-drawer-body {
      background-color: @background-color-public-group;
    }

    .ant-drawer-header {
      border-bottom: 1px solid @border;
    }
  }
}

.drawer-wrapper {
  .ant-input[disabled],
  [disabled],
  nz-switch {
    cursor: unset;
    background-color: white;
    border: 1px solid #f5f5f5;
  }

  .ant-switch-handle {
    cursor: unset;
  }
}

.ant-drawer-close {
  color: #86d0ef;

  .anticon-close,
  .anticon-close:hover,
  .anticon-close:active,
  .anticon-close:focus {
    color: #86d0ef;
  }
}