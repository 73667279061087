// ---------- reset

html, body {
  scroll-behavior: smooth;
}

* {
  font-family: Inter, sans-serif;
}

p, span, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

// ---------- default

form h3 {
  padding: 1rem;
  text-transform: uppercase;
}

nz-space .ant-space-item {
  margin-bottom: .5rem;
}