@import "../styles/colors";

.ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem;

  .ant-radio-button-wrapper {
    border: 1px solid @secondary-color;
    border-radius: 30px;
    padding: 0 2rem;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    transition: none;

    &:not(.ant-radio-button-wrapper-disabled) {
      &:hover {
        color: inherit;
        border: 1px solid @border;
      }
    }

    &:not(:first-child)::before {
      display: none;
    }
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: @btn-default-color;
    border: 1px solid @border;
    color: @secondary-color;
  }
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 48px;
  line-height: 48px;
}
