// ---------- Layout
@layout-body-background: #fff;
@layout-header-background: #fff;
@layout-header-height: 64px;
@layout-header-padding: 0 50px;
@layout-header-color: #fff;
@layout-footer-padding: 24px 50px;
@layout-footer-background: @layout-body-background;
@layout-sider-background: @layout-header-background;
@layout-trigger-height: 48px;
@layout-trigger-background: #fff;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;
// Layout light theme
@layout-sider-background-light: #fff;
@layout-trigger-background-light: #fff;
@layout-trigger-color-light: #fff;

// ---------- layout side menu item
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #F7F7F8;
  border-right: 3px solid #282c34;
}

.ant-menu-item-selected a {
  color: #282c34;
}
.ant-menu-item .ant-menu-title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-divider {
  background-color: @border;
}