.opn-input {
  @apply h-full w-full font-bold placeholder:font-normal
    min-h-10 p-2 bg-lightGrey rounded-xl outline-grey;

  &.opn-input-rounded {
    @apply rounded-full;
  }

  &.opn-input-error {
    @apply outline-error;
  }

  &-disabled {
    @apply cursor-not-allowed border border-grey;
  }
}

.opn-input-group {
  @apply block bg-lightGrey rounded-xl overflow-hidden;

  .opn-input-addon-wrapper {
    @apply w-full flex items-stretch;
  }

  .opn-input-affix-wrapper {
    @apply w-full flex items-center p-2 gap-1;
  }

  &-disabled {
    @apply cursor-not-allowed border border-grey;

    * {
      @apply pointer-events-none;
    }
  }
}

.opn-form-item {
  .opn-input {
    @apply min-h-0 px-4 pb-2 pt-1.5 bg-transparent rounded-none outline-none;
  }

  .opn-input-group {
    @apply min-h-0 bg-transparent rounded-none rounded-br-xl outline-none;

    .opn-input-affix-wrapper {
      @apply px-4 pb-2 pt-1.5;
    }
  }
}

.opn-input-group {
  .opn-input {
    @apply p-0 bg-transparent rounded-none outline-none min-h-0 border-0;
  }
}