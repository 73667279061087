.opn-select {
  @apply block w-full;

  .opn-select-front {
    @apply items-center flex flex-row overflow-hidden cursor-pointer outline-2 outline-grey rounded-br-xl
      min-h-10 bg-lightGrey rounded-xl;
  }

  &-focus {
    .opn-select-front {
      @apply outline;
    }
  }

  &-rounded {
    .opn-select-front {
      @apply rounded-full;
    }
  }

  &-disabled.opn-select-standalone {
    .opn-select-front {
      @apply cursor-not-allowed border border-grey;

      * {
        @apply pointer-events-none;
      }
    }
  }
}

.opn-form-item {
  .opn-select .opn-select-front {
    @apply min-h-0 p-0 pl-2 bg-transparent rounded-none rounded-br-xl outline-none;
  }
}