@import "ng-zorro-antd/ng-zorro-antd.less";
@import "flag-icons/css/flag-icons.min.css";

@import "colors";
@import "fonts";
@import "reset";
@import "layout";
@import "buttons";
@import "breadcrumb";
@import "inputs";
@import "table";
@import "tabs";
@import "date-picker";
@import "switch";
@import "form-items";
@import "select";
@import "steps";
@import "drawer";
@import "helpers";
@import "modal";
@import "checkbox";
@import "tabs";
@import "badge";
@import "radio-button";

