@import "../styles/colors";

// ---------- Buttons
@btn-font-weight: 600;
@btn-shadow: none;
@btn-primary-shadow: none;
@btn-text-shadow: none;
.ant-btn-lg {
  border-radius: 20px;
  height: 60px;
  padding: 0 40px;
}

.ant-btn {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
  }
}

// btn-primary
@btn-primary-color: @secondary-text-color;
@btn-primary-bg: @primary-color;
// btn-default
@btn-default-color: @text-color;
@btn-default-bg: #EDEFF0;
@btn-default-border: none;


.drawer-wrapper {
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    color: #282c34;
    border: 1px solid #282c34;
  }

  a.ant-btn:not([disabled]):hover {
    text-decoration: none;
    color: #282c34;
    border: none;
  }
}


// button confirm and refuse
.button {
  &-red {
    background-color: #E94B32;
    color: white;

    &:hover,
    &:active,
    &:focus {
      color: white;
      background-color: darken(#E94B32, 10%);
      border: 1px solid darken(#E94B32, 10%);
    }
  }

  &-confirm {
    color: white;
    background-color: #86d0ef;
    border: 1px solid #86d0ef;

    &:hover,
    &:active,
    &:focus {
      color: white;
      background-color: darken(#86d0ef, 10%);
      border: 1px solid darken(#86d0ef, 10%);
    }
  }
}

.secondary-button.ant-btn {
  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      color: unset;
      border: 1px solid @border;
    }
  }
}

.public-group-primary-button.ant-btn {
  color: white;
  background-color: @primary-color-public-group;
  border: 1px solid @primary-color-public-group;

  &:not([disabled]) {
    &:hover,
    &:active,
    &:focus {
      color: white;
      background-color: darken(@primary-color-public-group, 10%);
      border: 1px solid darken(@primary-color-public-group, 10%);
    }
  }

  &[disabled] {
    background-color: lighten(@primary-color-public-group, 50%);
    border: 1px solid lighten(@primary-color-public-group, 50%);
  }

}

.public-group-secondary-button.ant-btn {
  background-color: @secondary-color-public-group;
  border: 1px solid @border;

  &:not([disabled]) {
    &:hover,
    &:active,
    &:focus {
      color: unset;
      border: 1px solid darken(@secondary-color-public-group, 10%);
    }
  }

  &[disabled] {
    background-color: lighten(@secondary-color-public-group, 50%);
    border: 1px solid lighten(@secondary-color-public-group, 50%);
  }
}