@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@open/ngx-components/styles/styles.scss";

@layer base {
  html {}
  body {
    font-family: 'Montserrat', sans-serif;
  }
}
