@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/cdk/overlay-prebuilt.css';


@import '@open/ngx-components/form/styles/styles.scss';
@import '@open/ngx-components/input/styles/styles.scss';
@import '@open/ngx-components/select/styles/styles.scss';
@import '@open/ngx-components/menu/styles/styles.scss';
@import './fonts.scss';

html, body {
  font-family: 'Montserrat', system-ui, sans-serif;
}