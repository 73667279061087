.opn-form-item {
  @apply mb-8 relative flex flex-col justify-start items-stretch
    bg-lightGrey rounded-xl outline-2
    select-none;

  &-focus {
    @apply outline outline-grey;
  }

  &-error {
    @apply outline outline-error;
  }

  &-warning {
    @apply outline outline-orange;
  }

  &-success {
    @apply outline outline-ok;
  }

  &-validating {
    @apply outline outline-blue;
  }

  &-rounded {
    @apply px-2 rounded-full;
  }

  &-disabled {
    @apply cursor-not-allowed border border-grey;

    * {
      @apply pointer-events-none;
    }
  }
}

.opn-form-label {
  @apply px-4 w-full leading-relaxed text-sm text-grey;
}

.opn-form-control {
  @apply h-full w-full;
}
