// ---------- Breadcrumb
@breadcrumb-base-color: @text-color;
@breadcrumb-last-item-color: @primary-color;


nz-breadcrumb {
  display: flex;
  min-width: 440px;
}

nz-breadcrumb-item {
  padding: 16px 15px;
  line-height: 18px;
  background-color: #fff;

  &:first-child {
    position: relative;
    max-width: 240px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    span {
      color: white;
      position: relative;
      z-index: 1;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #282c34;
      border-radius: 20px;
    }
  }

  &.breadcrumb-arrow {
    padding: 16px 0;
  }
  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.ant-breadcrumb-separator {
  display: none;
}