@import "../styles/colors";

// => Input
.ant-form-item-control-input {
  position: relative;
  height: 100%;

  &-content {
    height: 100%;
  }
}


.ant-input {
  color: @text-color;
  font-size: 14px;
  font-weight: bold;
  background-color: @secondary-color;
  border: none;
  border-radius: 20px;
  padding: 2.5rem 0 1rem 1rem;
  height: 100%;
}

nz-input-group > .ant-input {
  border-radius: 0;
}

// input form without label
.ant-input.input-without-label {
  padding: 0 0 0 2rem;
  height: 4.5rem;
}

.ant-input.input-white {
  background-color: @layout-body-background;
}

.amount-wrapper {
  nz-input-group,
  nz-input-group.ant-input-affix-wrapper {
    height: 4.5rem;
    padding-top: 2rem;
    padding-left: 1rem;
    border: none;
    border-radius: 20px;
    width: 100%;
  }
}

.bounces-list-filter-entries.ant-input,
.paybacks-list-filter-entries.ant-input,
.beneficiaries-list-filter-entries.ant-input,
.agencies-list-filter-entries.ant-input,
.companies-list-filter-entries.ant-input,
.input-wrapper-filter-entries.ant-input,
.input-wrapper-filter-entries {
  padding: 1rem;

  &:focus,
  &-focused {
    border-color: unset;
    box-shadow: unset;
  }
}

.input-inner-svg {
  position: absolute;
  top: 50%;
  right: 1rem;
}