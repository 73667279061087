.ant-modal {

  .ant-modal-content {
    border-radius: 20px;
    overflow: hidden;
    padding: 2rem;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}

