@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 1 1000;
  src: url('../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 1 1000;
  src: url('../assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('woff2');
}