@import "../styles/colors";
// ---------- Steps
@steps-dot-size: 1.5rem;
@steps-dot-top: 0.50rem;
@steps-current-dot-size: 1.5rem;

.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 3rem);
  height: 7px;
  margin-left: 35px;
}

.ant-steps-item-tail::after {
  margin-bottom: 10px;
}

// ---------- step item title
.@{steps-prefix-cls}-item {
  &-title {
    font-size: 0.9rem;
    margin-left: 1.5rem;
  }
}

.ant-steps.public-group .ant-steps-item-process .ant-steps-icon-dot,
.ant-steps.public-group .ant-steps-item-finish .ant-steps-icon-dot {
  background-color: @primary-color-public-group;
  border: 3px solid white;
}

.ant-steps.public-group .ant-steps-item-tail::after {
  background-color: @disabled-public-group;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #2E4052;
}