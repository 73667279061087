@import "../styles/colors";

// ------- Table
@table-bg: @layout-body-background;
@table-header-bg: @layout-body-background;
@table-border-color: @layout-body-background;

.ant-table-wrapper::before {
  height: 0;
  display: block;
}
.ant-table table {
  border-collapse: separate;
  border-spacing: 0 10px;

  > thead > tr > th {
    font-weight: bold;
    border-width: 0;
    padding: 0 10px;

    &:first-child {
      padding-left: 50px;
    }
    &.ant-table-column-sort {
      background-color: inherit;
    }
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }

  > tbody > tr {
    cursor: pointer;

    &.ant-table-row > td.ant-table-cell,
    &.ant-table-row:hover > td.ant-table-cell,
    &.ant-table-expanded-row > td.ant-table-cell,
    &.ant-table-expanded-row:hover > td.ant-table-cell,
    &.ant-table-expanded-row::after {
      background: #f7f7f8;
    }

    &.redLine{
      &.ant-table-row > td.ant-table-cell,
      &.ant-table-row:hover > td.ant-table-cell,
      &.ant-table-expanded-row > td.ant-table-cell,
      &.ant-table-expanded-row:hover > td.ant-table-cell,
      &.ant-table-expanded-row::after {
        background: rgba(233, 75, 50, 0.1);
      }
    }

    &.ant-table-row {
      height: 50px;

      > td.ant-table-cell {
        background: #f7f7f8;
        padding: 0 10px;

        .redLines & {
          background: rgba(233, 75, 50, 0.1);
        }

        &:first-child {
          padding-left: 50px;
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
        }

        &:last-child {
          border-bottom-right-radius: 20px;
          border-top-right-radius: 20px;

          .ant-btn-link:hover,
          .ant-btn-link {
            border: none;
          }
        }
      }
    }

    &.ant-table-row.expanded {
      > td.ant-table-cell {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.ant-table-expanded-row {
      position: relative;

      > td {
        padding: 0 24px 24px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        > * {
          width: 100%;
          padding: 15px 15px 15px 25px;
          background-color: white;
          border-radius: 20px;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 11px;
        position: absolute;
        top: -11px;
        left: 0;
      }
    }
  }
}

.ant-table-wrapper.no-pagination .ant-pagination {
  display: none;
}

.classifications-form, .voucher-restrictions-list {
  .ant-table table {
    border-spacing: 0 0.5rem;//todo Voir avec charles ou seul tout ce qui peut etre enlevé
    padding: 0 .5rem;
  }
  td,th {
    padding: 0 .5rem;

  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td{
    border-right:unset;
  }
}

.benef-classifications-table{
  .ant-table table{
    border-spacing: 0 0.3rem ;
  }
}


.classifications-form, .voucher-restrictions-list {
  .ant-table-tbody > tr{
    &:hover{
    background: unset;

    }
    &.ant-table-row:hover,
    &.ant-table-row.active {

      background-color: rgba(134, 208, 239, 0.3);

      > td {
        background-color: transparent;
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
//check compatibility relevance
          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }
        .ant-input{
          background-color: white;
        }
      }

      > td:last-child {

        .ant-menu-submenu{
          background-color: transparent;
          &-arrow {
            display: none;
          }
        }
        .ant-menu-submenu-title{
          border-radius: 50%;
          background-color:#BDD6E2;
        }
      }
    }
  }

}

.publicGroupBackground,
.classifications-form.public-group,
.voucher-restrictions-list.public-group {
  .ant-table.ant-table-bordered > .ant-table-container,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border: none;
  }

  .ant-table table, .ant-table-thead > tr > th {
    background-color: @background-color-public-group;
  }

  .ant-table-tbody > tr.ant-table-placeholder {
    display: none;
  }

  .ant-table-tbody > tr {
    &.ant-table-row:hover,
    &.ant-table-row.active {
      background-color: darken(@background-color-public-group, 10%);
    }
  }
}

//todo check discrepancies with agency users  component and eventually remove
//todo rename in a more generic way if relevant

.table-dropdown {
  &-btn-red,
  &-btn-red:hover{
    color: #E94B32;
    width: 100%;
  }

  &-menu {
    .ant-menu-submenu-title {
      overflow: visible;
      padding: 0;
    }

    .ant-menu-submenu-arrow {
      display: none;
    }

    .ant-table-wrapper::before {
      height: 0;
    }
  }

  &-container {
    background-color: transparent;
    width: 40px;
    margin-left: auto;
    border-spacing: 0;

    .anticon {
      &.anticon-ellipsis {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #BDD6E2;
        display: flex;
      }

      svg {
        transform: rotate(90deg);
        width: 20px;
        height: auto;
        margin: auto;
      }
    }
  }
}

.ant-table-tbody > tr.ant-table-row {
  .table-dropdown-container {
    visibility: hidden;
  }

  &:hover {
    .table-dropdown-container {
      visibility: visible;
    }
  }
}
