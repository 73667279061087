.ant-checkbox-wrapper {
  align-items: center;
}

.ant-checkbox + span {
  padding-right: 0;
  padding-left: 1rem;
  line-height: 1rem;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2E4052;
  border-color: #2E4052;
}

.ant-checkbox-checked::after {
  border: 1px solid #2E4052;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #2E4052;
}

