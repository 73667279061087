// Switch
nz-switch {
  height: 4.5rem;
  padding-top: 2rem;
  padding-left: 1rem;
  background-color: inherit;
  border: none;
  border-radius: 20px;
}

nz-switch.emptyBackground {
  background-color: transparent;
}