@import "../styles/colors";

.business-contract .ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: @text-color;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #F7F7F8;
}


.business-contract .ant-badge-count, .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component {
  top: -2px;
  right: -2px;
}
