@import './colors';

// svg colors
.svg-blue {
  color: @primary-color;
}

.svg-black {
  color: @black;
}

.anticon svg {
  font-size: inherit;
}

// title near buttons (for example agencies/details/contract)
.lower-title {
  padding: 1rem;
  text-transform: uppercase;
}

.helper-text {
  color: @grey-light;
}

.bold {
  font-weight: 700;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}