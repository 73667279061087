@import "../styles/colors";

// => Select
.ant-select {
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 4.5rem;
    padding-top: 2rem;
    padding-left: 1rem;
    border: none;
    border-radius: 20px;
  }

  &-single .ant-select-selector .ant-select-selection-search {
    top: 2rem;
  }
}

.ant-select.ant-select-in-form-item .ant-select-selector {
  background-color: @secondary-color;
  border: 1px solid @secondary-color;
}

// select BG white
.ant-select.ant-select-in-form-item.select-white .ant-select-selector {
  background-color: @layout-body-background;
  border: 1px solid @layout-body-background;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: @layout-body-background;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: @component-background;
  border: 1px solid @border;
  cursor: not-allowed;
}

.agency-upper-container {
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
    position: relative;
    padding: .5rem 1.8rem 0 0;
    overflow: visible;
    color: @text-color;
    font-weight: bold;
  }

  .ant-select-arrow {
    position: relative;
    bottom: 62%;
    left: 92%;
  }
}

.millesime-table {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 2.5rem;
    width: 200px;
    padding-top: .27rem;
    padding-left: 1rem;
    border: none;
    border-radius: 20px;
  }

  & th {
    padding: 0 1rem
  }
}

// => Select icons
.ant-select-arrow,
.ant-select-clear {
  top: unset;
  bottom: 1rem;
}