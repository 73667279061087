@import "../styles/colors";

// Date picker
.ant-picker {
  height: 4.5rem;
  padding-top: 2rem;
  padding-left: 1rem;
  border: 1px solid @secondary-color;
  background-color: @secondary-color;
  border-radius: 20px;
  width: 100%;
}

.ant-picker.picker-white {
  border: 1px solid @layout-body-background;
  background-color: @layout-body-background;
}

.ant-picker.ant-picker-disabled {
  background: @component-background;
  border-color: @border;
}